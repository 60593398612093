<template>
  <div class="w-full">
    <choose-login-modal v-show="modal" @closeModal="modal = false" />
    <div class="w-full h-full flex flex-col items-center" :class="modal ? 'blur' : ''">
      <div class="wrapper">
        <div class="content container">
          <nav-bar @login="modal = true" />
          <div class="article">
            <p class="title">
              Empréstimo para <span class="text-primary">empresas</span>
            </p>
            <p class="title">
              financiado por <span class="text-primary">pessoas</span>
            </p>
            <p class="subtitle">
              Fácil, simples e justo
            </p>
            <div class="btn">
              <button class="btn_green" @click="$router.push('/emprestimo')">
                Quero empréstimo
              </button>
              <button class="btn_purple" @click="$router.push('/investimento')">
                Quero investir
              </button>
            </div>
            <img class="block w-full h-64 md:hidden object-cover object-bottom" alt="Pessoa"
              src="../../assets/images/landing/wrapper_people.webp">
            <div class="icons">
              <a class="icon_shadow" href="https://wa.me/5511991121535" rel="noreferrer" target="_blank">
                <div alt="Whatsapp" aria-label="Whatsapp" class="icon_wathsapp" />
              </a>
              <a class="icon_shadow" href="https://www.facebook.com/tutudigital/" rel="noreferrer" target="_blank">
                <div alt="Facebook" aria-label="Facebook" class="icon_facebook" />
              </a>
              <a class="icon_shadow" href="https://www.instagram.com/tutudigital/" rel="noreferrer" target="_blank">
                <div alt="Instagram" aria-label="Instagram" class="icon_instagram" />
              </a>
              <a class="icon_shadow" href="https://www.youtube.com/channel/UCRxkhScYYqZw7BSUwwafxrg" rel="noreferrer"
                target="_blank">
                <div alt="Youtube" aria-label="Youtube" class="icon_youtube" />
              </a>
              <a class="icon_shadow" href="https://www.linkedin.com/company-beta/16220823" rel="noreferrer"
                target="_blank">
                <div alt="Linkedin" aria-label="Linkedin" class="icon_linkedin" />
              </a>
            </div>
          </div>
          <div class="arrow">
            <i class="fas fa-arrow-down arrow_icon" />
          </div>
        </div>
      </div>
      <div class="infor_financed" style="display: none">
        <p class="infor_title container">
          Já financiamos {{ amountFunded | money }}
        </p>
      </div>
      <div class="relative flex flex-row flex-wrap h-full container">
        <div class="about_left">
          <p class="about_title">
            Pensado para você...
          </p>
          <div class="about_cards">
            <div v-for="(item, index) in about" :key="index" class="about_card">
              <p class="about_card_title">
                {{ item.title }}
              </p>
              <p class="about_card_text">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
        <div class="about_right">
          <div class="about_img" />
        </div>
      </div>
      <div class="container my-4 lg:my-12">
        <div class="how_working">
          <p class="how_working_title">
            É bem simples!
          </p>
          <p class="how_working_subtitle">
            Veja como funciona
          </p>
          <div class="block md:hidden lg:hidden">
            <carousel-items :items="how_imgs" />
          </div>
          <div class="how_working_cards">
            <div class="how_working_card">
              <div class="how_working_icon_1 lg:self-start" />
              <p class="how_working_card_text lg:self-start">
                Empresa cria uma solicitação do empréstimo na plataforma
              </p>
              <i
                class="hidden absolute inset-y-0 right-0 mt-8 mr-1 fas fa-arrow-right text-secondary text-lg lg:block" />
            </div>
            <div class="how_working_card">
              <div class="how_working_icon_2" />
              <p class="how_working_card_text">
                Submetemos ao nosso modelo proprietário de crédito, se aprovada
                oferecemos aos investidores
              </p>
              <i
                class="hidden absolute inset-y-0 right-0 mt-8 mr-1 fas fa-arrow-right text-secondary text-lg lg:block" />
            </div>
            <div class="how_working_card">
              <div class="how_working_icon_3" />
              <p class="how_working_card_text ml-4">
                Investidores registram seu interesse e com quanto participariam
                no financiamento
              </p>
            </div>
            <div class="how_working_card">
              <div class="how_working_icon_4 lg:self-end" />
              <p class="how_working_card_text lg:self-end">
                Feito a combinação das partes, a empresa recebe seu empréstimo e
                os investidores seus investimentos mensais
              </p>
              <i
                class="hidden absolute inset-y-0 left-0 mt-8 ml-2 fas fa-arrow-right text-secondary text-lg lg:block" />
            </div>
          </div>
        </div>
      </div>
      <div class="investor_main">
        <div class="investor_content container">
          <div class="investor_article">
            <p class="investor_title">
              Olá, INVESTIDOR!
            </p>
            <p class="investor_subtitle">
              Aqui o investimento é rentável e com retornos mensais.
            </p>
            <p class="investor_list_title">
              Confira alguma de nossas vantagens:
            </p>
            <p class="investor_list_text">
              - Investimentos a partir de R$ 200,00 e possibilidade de alocar em
              diferentes empresas;
            </p>
            <p class="investor_list_text">
              - Acesso facilitado, sem burocracia, 100% segura com contratação
              online;
            </p>
            <p class="investor_list_text">
              - Mitigação de riscos (modelo proprietário de crédito
              sofisticado);
            </p>
            <p class="investor_list_text">
              - Recebimento mensal do investimento.
            </p>
            <button class="investor_btn" @click="$router.push('/investimento')">
              Quero investir
            </button>
            <img class="block w-screen h-80 lg:h-88 mt-8 md:hidden lg:hidden"
              src="../../assets/images/landing/investor_people.webp" alt="">
          </div>
        </div>
        <div class="investor_download">
          <div class="relative container">
            <p class="investor_download_title">
              Mais facilidade para você
            </p>
            <p class="investor_download_subtitle">
              Em breve um aplicativo para você investidor!
            </p>
            <div class="block w-4/5 my-4 md:hidden lg:hidden">
              <img class="w-32 h-10" src="../../assets/images/landing/app-store.svg" alt="App Store">
              <img class="w-32 h-10 mt-3" src="../../assets/images/landing/google-play.svg" alt="Google Play">
            </div>
            <div class="investor_download_apps">
              <img alt="App Store" class="w-40 h-12 mr-4" src="../../assets/images/landing/app-store.svg">
              <img alt="Google Play" class="w-40 h-12" src="../../assets/images/landing/google-play.svg">
            </div>
          </div>
          <div class="investor_download_white" />
        </div>
        <div class="investor_phone" />
      </div>
      <div class="container mb-8 flex flex-col lg:flex-row">
        <div class="media_in">
          <div class="flex items-end lg:items-center mb-4 lg:mb-8">
            <p class="media_in_title">
              Tutu na mídia
            </p>
            <button class="media_in_icons" aria-label="Mídia" :class="tutuCurrent === 0 ? 'opacity-50' : ''"
              @click="tutuCurrent === 0 ? () => '' : (tutuCurrent -= 1)">
              <i class="fas fa-arrow-left media_in_arrow_left" />
            </button>
            <button class="media_in_icons" aria-label="Mídia" :class="
              tutuCurrent === tutuNaMidia.length - 1 ? 'opacity-50' : ''
            " @click="
  tutuCurrent === tutuNaMidia.length - 1
    ? () => ''
    : (tutuCurrent += 1)
">
              <i class="fas fa-arrow-right media_in_arrow_right" />
            </button>
          </div>
          <p class="media_in_date">
            {{ tutuNaMidia[tutuCurrent].date }}
          </p>
          <p class="media_in_text">
            {{ tutuNaMidia[tutuCurrent].title }}
            <a :href="tutuNaMidia[tutuCurrent].url" target="_blank" rel="noreferrer"
              class="text-base text-right text-blue-600">Ver mais...</a>
          </p>
          <img :alt="tutuNaMidia[tutuCurrent].title" :src="tutuNaMidia[tutuCurrent].logo" class="media_in_editor">
        </div>
        <div class="media_partners">
          <div class="flex items-end lg:items-center mb-4 lg:mb-8">
            <p class="media_partners_title">
              Depoimentos
            </p>
            <button class="media_in_icons" :class="depoimentosCurrent === 0 ? 'opacity-50' : ''" @click="
              depoimentosCurrent === 0 ? () => '' : (depoimentosCurrent -= 1)
            ">
              <i class="fas fa-arrow-left media_in_arrow_left" />
            </button>
            <button aria-label="Mídia" class="media_in_icons" :class="
              depoimentosCurrent === depoimentos.length - 1
                ? 'opacity-50'
                : ''
            " @click="
  depoimentosCurrent === depoimentos.length - 1
    ? () => ''
    : (depoimentosCurrent += 1)
">
              <i class="fas fa-arrow-right media_in_arrow_right" />
            </button>
          </div>
          <div class="flex items-center">
            <img class="w-16 h-16 rounded-full shadow mr-4 lg:mr-10" :src="depoimentos[depoimentosCurrent].avatar"
              alt="Avatar Depoimento">
            <div class="w-full flex justify-between items-center">
              <div class="w-9/12">
                <p class="text-secondary text-xl font-bold">
                  {{ depoimentos[depoimentosCurrent].author }}
                </p>
                <span class="flex">
                  <i class="fas fa-star mr-1" :class="
                    depoimentos[depoimentosCurrent].rating >= 1
                      ? 'text-green-400'
                      : 'text-gray-500'
                  " />
                  <i class="fas fa-star mr-1" :class="
                    depoimentos[depoimentosCurrent].rating >= 2
                      ? 'text-green-400'
                      : 'text-gray-500'
                  " />
                  <i class="fas fa-star mr-1" :class="
                    depoimentos[depoimentosCurrent].rating >= 3
                      ? 'text-green-400'
                      : 'text-gray-500'
                  " />
                  <i class="fas fa-star mr-1" :class="
                    depoimentos[depoimentosCurrent].rating >= 4
                      ? 'text-green-400'
                      : 'text-gray-500'
                  " />
                  <i class="fas fa-star" :class="
                    depoimentos[depoimentosCurrent].rating === 5
                      ? 'text-green-400'
                      : 'text-gray-500'
                  " />
                </span>
              </div>
              <img src="../../assets/images/landing/trustpilot.webp" alt="TrustPilot">
            </div>
          </div>
          <p class="text-sm text-gray-600 mt-6">
            {{ depoimentos[depoimentosCurrent].description }}
          </p>
        </div>
      </div>
    </div>
    <div id="msgChamadaChat">Deseja fazer uma <b>simulação de empréstimo</b> em poucos minutos? <sup><a
          href="javascript://" onclick="document.getElementById('msgChamadaChat').style.display = 'none'">x</a></sup>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar';
import Footer from '@/components/Footer/Footer';
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal';
import CarouselItems from '@/components/Carousel/Carousel';
import MoneyFilter from '@/mixins/moneyFilter';
import Depoimentos from '@/assets/js/depoimentos';

export default {
  name: 'Landing',
  components: {
    NavBar, Footer, ChooseLoginModal, CarouselItems,
  },
  mixins: [MoneyFilter],
  props: ['expired'],
  data() {
    return {
      modal: false,
      amountFunded: 215123,
      about: [
        {
          title: 'Justo',
          text:
            'Taxas de juros até 10x menores do que o mercado quando comparado a bancos e factorings',
        },
        {
          title: 'Simples',
          text:
            'Acesso facilitado e sem burocracia e com a contratação online e 100% seguro',
        },
        {
          title: 'Fácil',
          text:
            'Sem necessidade de garantia real, análise rápida e efetiva, e transparência na contratação',
        },
        {
          title: '100% Online',
          text:
            'Simulação de valores, cadastro da empresa e atendimento 100% online',
        },
      ],
      how_imgs: [
        {
          src: require('@/assets/images/landing/how_working_1.svg'),
          text: 'Empresa cria uma solicitação do empréstimo na plataforma',
        },
        {
          src: require('@/assets/images/landing/how_working_2.svg'),
          text:
            'Submetemos ao nosso modelo proprietário de crédito, se aprovada oferecemos aos investidores',
        },
        {
          src: require('@/assets/images/landing/how_working_3.svg'),
          text:
            'Investidores registram seu interesse e com quanto participariam no financiamento',
        },
        {
          src: require('@/assets/images/landing/how_working_4.svg'),
          text:
            'Feito a combinação das partes, a empresa recebe seu empréstimo e os investidores seus retornos de investimentos mensais',
        },
      ],
      depoimentos: Depoimentos,
      depoimentosCurrent: 0,
      tutuCurrent: 0,
      tutuNaMidia: [
        {
          title: 'Investimento de renda fixa que promete 1,5% ao mês? Existe!',
          date: '27/fev/2018',
          url:
            'https://economia.estadao.com.br/blogs/silvio-crespo/investimento-de-renda-fixa-que-promete-18-ao-mes-existe/',
          logo: require('@/assets/images/landing/estadao.svg'),
        },
        {
          title:
            'Por que as micro e pequenas empresas devem optar pelo “crowdlending” para turbinar seus negócios?',
          date: '15/jan/2019',
          url:
            'https://www.istoedinheiro.com.br/por-que-as-micro-e-pequenas-empresas-devem-optar-pelo-crowdlending-para-turbinar-seus-negocios/',
          logo: require('@/assets/images/landing/istoe.svg'),
        },
        {
          title:
            'Investimento a partir de R$ 1.000 promete pagar 50% ao ano. Vale a pena?',
          date: '21/mai/2018',
          url:
            'https://exame.abril.com.br/seu-dinheiro/investimento-a-partir-de-r-1-000-promete-pagar-50-ao-ano-vale-a-pena/',
          logo: require('@/assets/images/landing/exame.webp'),
        },
        {
          title: 'Banco Central divulga projetos selecionados para o Lift 2019',
          date: '02/jul/2019',
          url:
            'https://ifenasbac.com.br/blog/banco-central-divulga-projetos-selecionados-para-o-lift-2019/',
          logo: require('@/assets/images/landing/fenasbac.svg'),
        },
      ],
    };
  },
  mounted() {

    var str = `<script id="cosmobots-loader" 
    src="https://cosmobots.io/js/cosmo-plugin.js"
    cosmo-widget-id="f3b07836-7242-11ed-b1a2-a5b0ce24d840"
    cosmo-widget-shape="circle"
    cosmo-widget-frame="https://chat.cosmobots.io/popwebwidget?bot_id=f3b07836-7242-11ed-b1a2-a5b0ce24d840&background=https://cdn.neurologic.com.br/neurolead/img/avatar/3.png" cosmo-widget-chat="https://chat.cosmobots.io/widget?bot_id=f3b07836-7242-11ed-b1a2-a5b0ce24d840" \/>`;
    const parser = new DOMParser();
    const chatHtmlParsed = parser.parseFromString(str, 'text/html');

    Array.from(chatHtmlParsed.getElementsByTagName('script')).forEach(item => {
      const script = document.createElement('script');
      script.appendChild(document.createTextNode(item.innerText));

      if (item.src) {
        script.src = item.src;
      }
      Array.from(item.attributes).forEach(attr => {
        script.setAttribute(attr.name, attr.value);
      });

      if (!document.head.innerHTML.includes(script.outerHTML))
        document.head.appendChild(script);
    });



    if (this.expired) {
      this.$store.dispatch('notification', {
        type: 'error',
        message: 'Sessão expirada. Faça login novamente.',
      });
    }
    this.getAmountFunded();
  },
  methods: {
    async getAmountFunded() {
      try {
        this.amountFunded = 0; /* (await LandingService.getAmountFunded()).data.data */
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
#msgChamadaChat {
  position: fixed;
  background: none;
  border-radius: 50%;
  bottom: 15pt;
  display: inline;
  height: 50px;
  position: fixed;
  right: 90pt;
  top: auto;
  z-index: 991;
  visibility: visible;
  border-radius: 8px !important;
  padding-left: 17px;
  padding-right: 17px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 25%);
  border: 1px solid #d6d6d6;
  color: #555 !important;
  background: white;
  line-height: 50px !important;
  font-family: "Helvetica Neue", "Neue Helvetica W01", Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  font-size: 16px !important;
}

#msgChamadaChat sup {
  position: relative;
  top: -15px;
  right: -8px;

}

#msgChamadaChat sup a {
  color: #333;
  text-decoration: none
}

#msgChamadaChat::after {
  content: "";
  width: 16px;
  height: 32px;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  right: -16px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAMvSURBVHic7ZpPi9NAGMafbKtbd6vrrn8KXqTYo5dlv4D3epBC8SuIJ2/2c4i3eigFpVfBuyctpVePS0vBq4UeSui/vB6aMZPZzGTiip1J5wcvmSaTJc+T951MZgM4HA6HY3/xwpBB/+tCdkWaASK5MySrATy5MOM6BjCsNsIDcKDRT0eklUZ4AAqSYzJBaUKtMoI3gC+FJBHiPpVQa0woInkMSBsXiOuTJFZ1zCg8ADc0+lHGtuxc4+AzQLzrsjLwhDbr50nOke03AmaArAxEcWxLCeeIhlhBEfHHoFi7aXeVFy1uk/obhywDxDRnbVVZWGnCge/775vN5iG2mSALZpL4mzdPtjUbIqLVavW90+k8AXDCxZ0wbodRBnAM4AjALQAlAIcAbmL7JCmGUQgjySjzTKGQzWbzs9/vPwNwGsbdMJgZzAhdE1TZYgweEf2pSyKaj0aj17Va7QvXhyQRKI4Rdy6/heT37qCrBNPp9F2lUnkI4D6AewDOEGUFy4Yy1JlgRxYkGEBERPP5/HOj0XgM4AEiI0QT+HJQlYJ9BhARLZfLH+12+wJ6JtiZBSoDwsHx12AweAGAlQQrhxNcLYUkA8zOgjQDiIiCIFiMx+M3iDLhDOossKcMdAxgzGaz7vn5+SNEpSBmQZYyMIMsBhAR+b7/tdVq1RDPgrQyyI8BRETr9fqy2+0+RdyAY1hoQGwilIXFYvGtVCo9RzQhCoS2amIktneGzoqwDCMEXJe/MmCz2Vz2er1X3C7j7qw2bhDMQB4fg0WdTkS0nEwmb6vV6kekD24QjkHSxwhSx4AgCKbD4fBltVr9BL3XYLvGA1XK7/XL0D6/Du/VgkhsEKR/vyRmPuy27+uiqFsW933/Q71eP0UklI8y4sKziDf/7mN7UUeQX5z4TBcnP6olcJ2PLHZOEXERSR82qARaLR7YGhBA/kWHzAgdg2R/xyj4DFB9IKFa0LBr6ivADADUFy8TqSPeaFN4A9LIKjztmBEUkPz/fJ06tlo4g58K6wpME2eNeCCeASp0RFklnCH7TDYLVgpnaC2JSbBaOCOrAbkQzZNmQO4EOxwOh8MR8RsgtSyqPuIxtgAAAABJRU5ErkJggg==);
  background-size: 32px 32px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
</style>

<style lang="sass" scoped>
@tailwind utilities

.wrapper
  @apply relative flex flex-col w-full h-full
  background-image: url("../../assets/images/landing/wrapper.webp")
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  @screen lg
    height: 768px

.blur
  transition-duration: .25s
  filter: blur(8px)
  -webkit-filter: blur(8px)

.content
  @apply relative
  @screen md
    @apply w-full
    height: 550px
    background-image: url('../../assets/images/landing/wrapper_people.webp')
    background-size: 70%
    background-position: bottom right -4rem
    background-repeat: no-repeat
  @screen lg
    height: 768px
    background-position: bottom right -6rem

.arrow
  @apply hidden
  @screen lg
    @apply block absolute flex items-center justify-center bottom-0 right-0 mr-32 h-6 w-10 rounded-t-full bg-secondary

.arrow_icon
  @apply text-white text-xss

.article
  @apply self-center mt-6 w-full
  @screen lg
    @apply self-start my-auto pt-40

.title
  @apply text-left text-xl font-bold text-secondary leading-relaxed
  @screen md
    @apply text-2xl
    &:first-child
      @apply mt-20
  @screen lg
    @apply text-4xl
    &:first-child
      @apply mt-0

.subtitle
  @apply text-left text-secondary mt-6
  @screen lg
    @apply text-2xl my-6

.btn
  @apply flex justify-between mt-6
  @screen md
    @apply mt-10 justify-start

.btn_green
  @apply w-1/2 h-8 bg-primary text-xs text-white rounded-full mr-2
  @screen md
    @apply w-40 h-10 ml-0 mr-4 text-base
    &:hover, &:focus
      @apply outline-none
  @screen lg
    @apply w-48 h-12

.btn_purple
  @apply w-1/2 h-8 bg-secondary text-xs text-white rounded-full ml-2
  @screen md
    @apply w-40 h-10 text-base ml-0 mr-0
    &:hover, &:focus
      @apply outline-none
  @screen lg
    @apply w-48 h-12

.icons
  @apply absolute bottom-0 left-0 mb-2 w-full flex justify-around
  @screen md
    @apply w-1/2 flex justify-start mb-4 left-auto

.icon_shadow
  @apply w-10 h-10 bg-white shadow-md rounded-full cursor-pointer
  &:first-of-type
    @apply ml-0
  &:last-of-type
    @apply mr-0
  @screen md
    @apply mr-6

.icon_wathsapp
  @apply w-10 h-10 bg-center bg-no-repeat
  background-image: url('../../assets/images/landing/icon_wathsapp.svg')

.icon_facebook
  @apply w-10 h-10 bg-center bg-no-repeat
  background-image: url('../../assets/images/landing/icon_facebook.svg')

.icon_instagram
  @apply w-10 h-10 bg-center bg-no-repeat
  background-image: url('../../assets/images/landing/icon_instagram.svg')

.icon_youtube
  @apply w-10 h-10 bg-center bg-no-repeat
  background-image: url('../../assets/images/landing/icon_youtube.svg')

.icon_linkedin
  @apply w-10 h-10 bg-center bg-no-repeat
  background-image: url('../../assets/images/landing/icon_linkedin.svg')

.infor_financed
  @apply flex flex-col justify-center h-16 bg-secondary w-full
  @screen lg
    @apply h-20 self-start

.infor_title
  @apply text-lg text-white font-bold
  @screen lg
    @apply text-3xl

.about_left
  @apply w-full
  @screen lg
    @apply w-1/2

.about_title
  @apply text-2xl text-secondary font-bold mt-8
  @screen lg
    @apply text-4xl leading-loose mt-12

.about_cards
  @apply w-full flex flex-row  justify-between flex-wrap

.about_card
  @apply w-5/12 mt-4
  @screen md
    @apply w-1/3
  @screen lg
    @apply w-1/2

.about_card_title
  @apply text-primary text-base leading-loose
  @screen md
    @apply text-center text-xl
  @screen lg
    @apply text-2xl text-left

.about_card_text
  @apply text-gray-700 text-xs
  @screen md
    @apply w-10/12 mx-auto text-center text-sm
  @screen lg
    @apply w-48 mx-0 text-left

.about_right
  @apply hidden
  @screen lg
    @apply w-1/2 flex flex-col items-end

.about_img
  @apply mt-6 bg-no-repeat bg-cover w-full h-120
  background-image: url('../../assets/images/landing/about_img.webp')
  background-position: right
  background-size: 90%

.how_working_title
  @apply text-2xl text-secondary font-bold leading-loose mt-6
  @screen lg
    @apply text-4xl

.how_working_subtitle
  @apply text-primary text-base
  @screen lg
    @apply text-2xl leading-loose

.how_working_cards
  @apply hidden
  @screen md
    @apply block w-full flex flex-wrap

.how_working_card
  @apply mt-8
  @screen md
    @apply relative w-1/4 mt-10 flex flex-col items-center

.how_working_icon_1
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../assets/images/landing/how_working_1.svg')
  @screen lg
    @apply w-40 ml-4 h-20

.how_working_icon_2
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../assets/images/landing/how_working_2.svg')
  @screen lg
    @apply w-40 mr-2 h-20

.how_working_icon_3
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../assets/images/landing/how_working_3_2.svg')
  @screen lg
    @apply w-40 ml-2 h-20

.how_working_icon_4
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../assets/images/landing/how_working_4.svg')
  @screen lg
    @apply w-40 mr-4 h-20

.how_working_card_text
  @apply w-10/12 text-center text-gray-700 text-sm
  @screen lg
    @apply w-48

.investor_main
  @apply relative w-full flex flex-col items-center bg-secondary

.investor_content
  @screen md
    @apply relative bg-no-repeat bg-contain w-full flex flex-col items-center bg-secondary
    background-image: url('../../assets/images/landing/investor_people.webp')
    background-size: 56%
    background-position: bottom left 2rem
  @screen lg
    background-size: 45%
    background-position: bottom left 3rem
  @screen xl
    background-size: 39%
    background-position: bottom left 9rem

.investor_article
  @apply w-full flex flex-col items-center
  @screen md
    @apply w-2/5 self-end mt-2 items-start mb-8
  @screen lg
    @apply w-112 mb-24

.investor_title
  @apply text-2xl text-primary font-bold leading-loose mt-12
  @screen lg
    @apply text-4xl mt-6

.investor_subtitle
  @apply text-white font-semibold text-base text-center mt-5
  @screen md
    @apply text-left mt-0
  @screen lg
    @apply text-2xl

.investor_list_title
  @apply hidden
  @screen md
    @apply block text-white text-sm leading-loose my-4

.investor_list_text
  @apply hidden
  @screen md
    @apply block text-white text-sm leading-loose

.investor_btn
  @apply w-40 h-8 bg-primary text-white rounded-full mt-8
  @screen md
    @apply w-40 h-10 z-30

.investor_download
  @apply w-full flex flex-col items-center bg-primary
  background-image: url('../../assets/images/landing/phone.webp')
  background-size: 200px
  background-position: bottom -3rem right -2rem
  background-repeat: no-repeat
  @screen md
    @apply w-full flex flex-col items-center bg-primary
    background-image: none

.investor_download_title
  @apply text-2xl text-white text-center font-bold leading-relaxed mt-8
  @screen md
    @apply text-left mt-6
  @screen lg
    @apply text-4xl

.investor_download_subtitle
  @apply text-white text-center font-semibold text-base mt-4
  @screen md
    @apply text-left
  @screen lg
    @apply text-2xl

.investor_download_apps
  @apply hidden
  @screen md
    @apply block w-4/5 flex mt-6 mb-10

.investor_download_btn
  @apply hidden
  @screen md
    @apply block w-40 h-10 bg-secondary text-white mr-6 rounded-full z-30

.investor_download_google
  @apply w-40 h-10 bg-center bg-no-repeat rounded-lg z-30
  background-image: url('../../assets/images/landing/playstore.webp')

.investor_download_white
  @apply hidden
  @screen md
    @apply block w-full h-10 bg-white border-none z-10
  @screen lg
    @apply h-16

.investor_phone
  @apply hidden
  background-image: none
  @screen md
    @apply block w-full absolute z-10
    height: 100%
    background-image: url('../../assets/images/landing/phone.webp')
    background-size: 300px 300px
    background-position: right bottom
    background-repeat: no-repeat
  @screen lg
    @apply z-20
    width: 800px
    background-size: 400px 400px

.media_in
  @apply w-full
  @screen lg
    @apply w-1/2

.media_in_title
  @apply text-xl text-secondary font-bold leading-relaxed mr-6 mt-6
  @screen lg
    @apply text-4xl mt-0 mr-12

.media_in_icons
  @apply bg-primary h-8 w-8 rounded-full outline-none
  &:first-of-type
    @apply mr-2
  @screen lg
    @apply h-10 w-10

.media_in_arrow_left
  @apply bg-no-repeat bg-contain text-white h-4 w-4

.media_in_arrow_right
  @apply bg-no-repeat bg-contain text-white h-4 w-4

.media_in_date
  @apply text-sm text-gray-600 opacity-50

.media_in_text
  @apply w-4/5 text-base text-gray-600 leading-normal mt-4
  @screen lg
    @apply text-2xl

.media_in_editor
  @apply h-20 w-32

.media_partners
  @apply w-full
  @screen lg
    @apply w-1/2

.media_partners_title
  @apply text-xl text-secondary font-bold leading-relaxed mr-6 mt-6
  @screen lg
    @apply text-4xl mt-0 mr-12

.media_partners_icons
  @apply flex flex-wrap mt-4

.media_partners_icon_1
  @apply bg-no-repeat bg-contain h-16 w-32 mr-4
  background-image: url('../../assets/images/landing/ieptb.webp')

.media_partners_icon_2
  @apply bg-no-repeat bg-contain h-16 w-32
  background-image: url('../../assets/images/landing/serasa.webp')
</style>
