export default [{
        author: 'Tury Mota',
        description: 'Uma empresa com credibilidade, seriedade e ágio em viabilizar crédito.',
        rating: 5,
        avatar: require('@/assets/images/landing/trustpilotclient.webp')
    },
    {
        author: 'Katya',
        description: 'A tutu me ajudou em um momento muito importante na minha vida, muito atenciosos e comprometidos com o cliente. Obrigada!',
        rating: 5,
        avatar: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
    },
    {
        author: 'Tito',
        description: `Foi muito bom conhecer a Tutu Digital onde realizei um empréstimo Pessoa Jurídica. Só achei que o tempo do parcelamento (6 meses) para os respectivos pagamentos foi BEM CURTO.
    Para empresas obter capital de giro é necessário um prazo maior; 12 a 24 meses.
    Mas mesmo assim, acho que fiz um bom negócio pois os juros cobrados são bens menores do que os bancos comerciais.
    Tito`,
        rating: 5,
        avatar: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
    },
    {
        author: 'Vander de Lucca',
        description: 'Meu problema resolvido, simples e objetivo.',
        rating: 5,
        avatar: 'https://user-images.trustpilot.com/5d937c2cdc4e937f35d53374/73x73.png'
    },
    {
        author: 'Wagner Fonseca',
        description: 'Muito rápida e sensível ao problema do cliente, parabéns!',
        rating: 5,
        avatar: 'https://user-images.trustpilot.com/5d8512af39cf142c246ec1c0/73x73.png'
    },
    {
        author: 'Edvania Silva',
        description: 'Totalmente confiável, retorno dentro do prazo previsto. Atendeu nossas expectativas.',
        rating: 5,
        avatar: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
    },
    {
        author: 'Reinaldo Bernardi',
        description: `boa tarde
    pessoal de apoio da plataforma muito atenciosos e prestativos
    tirando todas as dúvidas e orientando corretamente
    estão de parabéns
    Reinaldo
    RV TÊXTIL`,
        rating: 4,
        avatar: 'https://user-images.trustpilot.com/5d111776a0bdc7081448a455/73x73.png'
    },
    {
        author: 'Memórias em Horas',
        description: 'Muito atenciosos e extremamente profissionais!',
        rating: 5,
        avatar: 'https://user-images.trustpilot.com/5d025ba06eafc23675d6bc98/73x73.png'
    },
    {
        author: 'Nathan',
        description: 'Indico e recomendo. Tinha tomado três golpes e a Tutu apareceu para salvar minha empresa. Sempre que eu eu precisar, vou procurá-los. São confiáveis e muito profissionais.',
        rating: 5,
        avatar: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
    },
    {
        author: 'Kawê Pinheiro',
        description: 'Ótimo atendimento, bem claro e fácil !',
        rating: 5,
        avatar: 'https://user-images.trustpilot.com/5cf839dcbd9520033b36b349/73x73.png'
    },
    {
        author: 'João Amaro Buck',
        description: 'Foi muito bom, resolveu mesmo minha situação, parece até mentira de tão fácil.',
        rating: 5,
        avatar: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
    }
]